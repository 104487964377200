<template>
  <LiefengContent>
    <template v-slot:title>获奖名单管理</template>
    <template v-slot:toolsbarLeft>
         <Input
         :maxlength="20"
        v-model.trim="searchData.userName"
        placeholder="按姓名查询"
        style="width: 150px"
      />
      <Button type="primary" icon="ios-search" @click="getList(searchData)">查询</Button>
    </template>
    <template v-slot:toolsbarRight>
      <Form inline  label-position="left" :label-width="70">
        <FormItem label="兑奖类型">
          <Select
            @on-change="searchList"
            v-model="prizeType"
            style="width: 150px"
          >
            <Option
              :value="item.value"
              v-for="item in prizeTypeList"
              :key="item.value"
              >{{ item.label }}</Option
            >
          </Select>
        </FormItem>
      </Form>
       <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="pageNumber"
        @hadlePageSize="getList"
        :loading="loading"
        :pagesizeOpts="[20, 30, 50, 100]"
        :fixTable="true"
        :pageSize="pageSize"
      ></LiefengTable>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/sponsoranward")
import { format } from "@/utils/formatTime";
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      loading: false,
      hideSearch: false,
      showDo: false, //false 为隐藏
      hideDel: true,
      hideEdit: true,
      hidenBread: true,
      total: 0,
      pageNumber: 1,
      pageSize: 20,
      prizeType: "",
      talbeColumns: [
        {
          title: "姓名",
          key: "userName",
          width: 110,
          align: "center",
        },
        {
          title: "联系电话",
          key: "mobile",
          align: "center",
        },
        {
          title: "中奖内容",
          key: "activityName",
          align: "center",
        },
        {
          title: "兑奖码",
          key: "redeemCode",
          align: "center",
        },
        {
          title: "兑奖截止时间",
          key: "endTime",
          align: "center",
        },
        {
          title: "中奖时间",
          key: "sendTime",
          align: "center",
        },
        {
          title: "兑换状态",
          key: "status",
          width: 110,
          align: "center",
        },
        // {
        //     title: "来源",
        //     key: "custGlobalId",
        //     width: 110,
        //     align: "center",
        // },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: 110,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "5px",
                  },
                  on: {
                    click: () => {
                      this.getPrize(params);
                    },
                  },
                },
                "兑奖"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      prizeTypeList: [
        {
          value: "1",
          label: "待发放",
        },
        {
          value: "2",
          label: "待兑换",
        },
        {
          value: "3",
          label: "已兑换",
        },
        {
          value: "4",
          label: "已失效",
        },
      ],
      searchData: {
        userName: "",
        status: "1",
        page: 1,
        pageSize: this.pageSize,
      },
    };
  },
  methods: {
    //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "姓名",
            "联系电话",
            "中奖内容",
            "兑奖码",
            "兑奖截止时间",
            "中奖时间",
            "兑换状态"
          ];
          let filterVal = [
            "userName",
            "mobile",
            "activityName",
            "redeemCode",
            "endTime",
            "sendTime",
            "status",
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "中奖名单列表"
          );
        },
      });
    },
    getList(data) {
      this.loading = true;
      if (data) {
        this.searchData = {
          userName: data.userName,
          status: data.status,
          page: data.pageNum,
          pageSize: data.pageSize,
        };
        //为删除预留  当前页
        this.pageNumber = data.pageNum;
        this.pageSize = data.pageSize;
      } else {
        this.searchData = {
          page: this.pageNumber,
          pageSize: this.pageSize,
        };
      }
      //获取中奖名单表格数据，此接口已失效，暂无接口
      this.$get("/old/api/finger/prizeredeem/selectPage", this.searchData).then(
        (res) => {

          if (res.code === "200") {
            this.total = res.maxCount;
            console.log(res.dataList);
            res.dataList.forEach((item) => {
              item.status =
                item.status == 1
                  ? "待发放"
                  : item.status == 2
                  ? "待兑换"
                  : item.status == 3
                  ? "已兑换"
                  : "已失效";
              item.sendTime = format(item.sendTime, "yyyy-MM-dd HH:mm");
              item.endTime = format(item.endTime, "yyyy-MM-dd HH:mm");
            });
            this.tableData = res.dataList;
            this.loading = false;
          }else {
            this.$Message.error({
              background: true,
              content: "数据获取失败"
            })
             this.loading = false;
          }
        }
      ).catch(err => {
        this.$Message.error({
          background: true,
          content: "本页面已放弃"
        })
      })
    },
    getPrize(params) {
      let status =
        params.row.status == "待发放"
          ? 1
          : params.row.status == "待兑换"
          ? 2
          : params.row.status == "已兑换"
          ? 3
          : 4;
      //     status == "已失效" ?
      //     4 :
      //     "已失效";
      if (status < 3) {
        this.$post("/old/api/finger/prizeredeem/updateStatus", {
          id: params.row.id,
          status: status,
        })
          .then((res) => {
            if (res.code === "200") {
              this.$Message.success("操作成功");
              this.getList();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$Message.error("奖品已兑换或失效");
      }
    },
    searchList(status) {
      if (status) {
        this.searchData.status = status;
        this.getList(this.searchData);
      }
    },
  },
  created() {
    this.getList();
  },
  components: {
    LiefengContent,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
.ivu-form-item {
    margin-bottom: 0;
}
</style>